<template>
    <CModal
        :show.sync="users_modal"
        color="primary"
        size="lg"
        :closeOnBackdrop="false"
        >
        <template #header>
            <h5> <font-awesome-icon icon="user"/> Users list </h5>
            <CButton 
                class="pull-right" 
                color="light" 
                shape="pill" 
                size="sm"
                @click="users_modal = false"
            >
                <font-awesome-icon icon="times"/>
            </CButton>
        </template>
        <template #body-wrapper>
            <CCardBody>
                <CDataTable
                    :items="users"
                    :fields="users_fields"
                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                    :items-per-page="10"
                    items-per-page-select
                    border
                    sorter
                    pagination
                    @row-clicked="selectedUser"
                    :loading="table_loading"
                >
                </CDataTable>
            </CCardBody>
        </template>
        <template #footer>
            <hr hide>
        </template>
    </CModal>
</template>
<script>
export default {
    name: 'UsersModal',
    data() {
        return {
            table_loading: true,
            users_modal: false,
            clear_selected: false,
            selected: {
                id: null,
                name: null,
            },
            users: [],
            users_fields: [
                {key: 'user_id', label: 'ID'},
                {key: 'employee_number', label: 'Employee Number'},
                {key: 'name', label: 'Name'},
                {key: 'department'},
            ]
        }
    },
    created() {
        this.$parent.$on('show_users_modal', this.getUsers);
    },
    methods: {
        /**
         * This getUsers function returns list of users
         * 
         * @param object parameter
         * The object has default value of null.
         * 
         * parameter: {
         *      department: (id) 
         *      first_name: (string)
         *      middle_name: (string)
         *      last_name: (string)
         * }
         * 
         * @return the required data
         */
        getUsers: function(parameter=null) {
            this.$Progress.start()
            axios.post('/users/list-with-parameter/', parameter, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.users = []
                    for(let i = 0; i < response.data.data.length; i++) {
                        let option = {
                            user_id: response.data.data[i].id,
                            employee_number: response.data.data[i].employee_number,
                            name: `${response.data.data[i].first_name} ${response.data.data[i].last_name}${response.data.data[i].suffix ? ' '+response.data.data[i].suffix: ''}`,
                            department: response.data.data[i].department ? response.data.data[i].department.name : 'N/a',
                        }
                        this.users.push(option)
                    }
                    this.table_loading = false
                    this.$Progress.finish()
                }
            })
        },
        selectedUser: function(item, index, column, event){
            this.$emit('selectedUser', {id: item.user_id, name: item.name})
            this.users_modal = false
        }
    },
    watch: {
        clear_selected: function (value) {
            if(value) {
                this.selected.id = null;
                this.selected.name = null;
            }
        }
    }
}
</script>